import React from "react";
import { graphql } from "gatsby";
import Obfuscate from "react-obfuscate";
import { FaTwitter, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import { BsArrowRight } from "react-icons/bs";

import Layout from "components/commercial/Layout";
import SimpleMap from "components/Map";
import NextSection from "components/NextSection";
import LogoShape from "images/LogoShape";

export const query = graphql`
  {
    wp {
      page(id: "/commercial/contact/", idType: URI) {
        id
        title
        slug
        pageContact {
          nextSection {
            label
            number
            pageLink {
              ... on WP_Page {
                id
                uri
              }
            }
            heading
            image {
              ...MediaItem
            }
          }
          formTitle
          formDescription
          addressTitle
          pageTitle
        }
      }
      themeGeneralSettings {
        globalOptions {
          address
          email
          phone
          twitter
          linkedin
          instagram
        }
      }
    }
  }
`;

const contact = ({ data }) => {
  const { title, pageContact } = data.wp.page;
  const generalFields = data.wp.themeGeneralSettings.globalOptions;
  const {
    nextSection: { label, pageLink, heading, number, image },
  } = pageContact;

  return (
    <Layout title={title}>
      <main>
        <div className="relative bg-everglade-500 md:bg-transparent">
          <div className="hidden md:block absolute top-0 left-0 bottom-0 z-0 h-full w-7/12 bg-everglade">
            <LogoShape
              fill={"#fff"}
              className="absolute bottom-0 hidden lg:block z-20 opacity-100 w-[300px] h-auto right-[15%] transform rotate-90 translate-y-[60%] mix-blend-overlay"
            />
          </div>
          <div className="relative z-10 md:container mx-6 md:mx-auto pt-32 flex flex-col md:flex-row gap-4">
            <div className="md:w-3/6 md:px-6 lg:px-0">
              <div className="text-cinnabar uppercase tracking-wider flex items-center text-sm mb-6">
                04{" "}
                <span className="inline-block mx-2 w-11 bg-cinnabar h-[1px]"></span>{" "}
                {title}
              </div>
              <h2 className="text-4xl tracking-wider text-white uppercase font-bold mb-10">
                {pageContact.pageTitle}
              </h2>
              <div className="flex flex-col md:flex-row gap-8 md:pb-4 lg:pb-0 lg:gap-16">
                <div className="">
                  <h3 className="uppercase tracking-wider text-cinnabar font-bold">
                    {pageContact.formTitle}
                  </h3>
                  <div className="mt-4 flex flex-col gap-4 text-white text-sm">
                    <p>{pageContact.formDescription}</p>
                    <form action="" className="flex flex-col gap-4">
                      <input
                        className="bg-mineral px-2 py-1 text-xs focus:ring-1 focus:outline-none ring-cinnabar-500"
                        type="text"
                        placeholder="Name"
                        required
                      />
                      <input
                        className="bg-mineral px-2 py-1 text-xs focus:ring-1 focus:outline-none ring-cinnabar-500"
                        type="email"
                        placeholder="Email"
                        required
                      />
                      <input
                        className="bg-mineral px-2 py-1 text-xs focus:ring-1 focus:outline-none ring-cinnabar-500"
                        type="text"
                        placeholder="Subject"
                        required
                      />
                      <textarea
                        className="bg-mineral px-2 py-1 text-xs focus:ring-1 focus:outline-none ring-cinnabar-500"
                        name=""
                        id=""
                        cols="30"
                        rows="6"
                        placeholder="Message"
                        required
                      ></textarea>
                      <button
                        type="submit"
                        className="flex w-auto text-cinnabar-500 items-center mt-4 transition hover-hover:hover:text-white"
                      >
                        SUBMIT <BsArrowRight className="text-xl ml-2" />
                      </button>
                    </form>
                  </div>
                </div>
                <div className="">
                  <h3 className="uppercase tracking-wider text-cinnabar font-bold">
                    {pageContact.addressTitle}
                  </h3>
                  <div className="flex flex-col space-y-4 text-white text-sm">
                    <address className="not-italic">
                      {generalFields.address}
                    </address>
                    <div className="">
                      <div>
                        <span className="text-skin-accent">T:</span>
                        <Obfuscate
                          className="ml-2 hover:underline"
                          email={generalFields.phone}
                        />
                      </div>
                      <div>
                        <span className="text-skin-accent">E:</span>
                        <Obfuscate
                          className="ml-2 hover:underline"
                          email={generalFields.email}
                          headers={{
                            subject: "Enquiry",
                          }}
                        />
                      </div>
                    </div>
                    <div className="">
                      {/* Social links */}
                      <div className="text-skin-accent">Follow us</div>
                      <div className="mt-2 flex space-x-4 items-center">
                        <a
                          href={generalFields.twitter}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FaTwitter />
                        </a>
                        <a
                          href={generalFields.linkedin}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FaLinkedinIn />
                        </a>
                        <a
                          href={generalFields.instagram}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FaInstagram />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full h-96 my-8 md:my-0 md:w-5/12 md:absolute lg:relative md:bottom-0 md:right-0 lg:bottom-auto lg:right-auto lg:w-3/6 md:h-[80%] lg:h-[900px] xl:h-[calc(100vh-120px)]">
              {/* Map */}
              <SimpleMap />
            </div>
          </div>
        </div>
        <NextSection
          number={number}
          label={label}
          link={pageLink.uri}
          background="bobalife"
          heading={heading}
          image={image}
        />
      </main>
    </Layout>
  );
};

export default contact;
