import React from "react";
import GoogleMapReact from "google-map-react";
import { FaMapMarkerAlt } from "react-icons/fa";

const AnyReactComponent = ({ text }) => (
  <div className="inline-block">{text}</div>
);

const SimpleMap = () => {
  let defaultProps = {
    center: {
      lat: 52.46127057422396,
      lng: -1.9434619728554565,
    },
    zoom: 14,
  };
  let createMapOptions = (maps) => {
    return {
      disableDefaultUI: true,
    };
  };
  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "100%", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: `${process.env.GATSBY_GOOGLE_MAPS_API_KEY}` }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        options={createMapOptions}
      >
        <FaMapMarkerAlt
          lat={52.46127057422396}
          lng={-1.9434619728554565}
          className="text-cinnabar text-3xl"
        />
      </GoogleMapReact>
    </div>
  );
};
export default SimpleMap;
